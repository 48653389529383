export const DEFAULT_AUTH_HEADER = {
	headers: {
		"Authorization": "Bearer " + process.env.REACT_APP_DEFAULT_API_KEY
	}
};

export const ZOOM_AUTH_HEADER = {
	headers: {
		"Authorization": "Bearer " + process.env.REACT_APP_DEFAULT_API_KEY
	}
};

//todo: to be changed on role revisions 
export const ROLE_ADMIN = 1;
export const ROLE_STUDENT = 2;
export const ROLE_MENTOR = 3;
export const ROLE_SUPER_ADMIN = 4;
export const ROLE_EMPLOYEE = 5;
export const ROLE_OWNER = 6;

//todo: to be changed on role revisions 
export const ROLES = [
	{ value: ROLE_ADMIN, label: "Admin" },
	{ value: ROLE_STUDENT, label: "Student" },
	{ value: ROLE_MENTOR, label: "Mentor" },
	{ value: ROLE_SUPER_ADMIN, label: "Super Admin" },
	{ value: ROLE_EMPLOYEE, label: "Employee" },
	{ value: ROLE_OWNER, label: "Owner" }
];

export const DIR_DEFAULT_RESOURCE= "/resource_uploads/"
export const DIR_LIBRARY_IMAGE = "/library_uploads/images/";
export const DIR_COMPANY_IMAGE = "/company_uploads/images/";
export const DIR_LESSON_AUDIO = "/lesson_attachment_uploads/audios/";
export const DIR_LESSON_UPLOAD = "/lesson_attachment_uploads/documents/";
export const DIR_USER_PROFILE = "/user_profile_images/";
export const DIR_INSTRUCTOR_PROFILE = "/instructor_profile_images/";
export const DIR_VID_SUBTITLES = "/lesson_attachment_uploads/video_subtitles/";
export const DIR_QUOTES_TEMPLATES = "/resource_uploads/quotes_templates/"

export const DEFAULT_LIBRARY_IMAGE = process.env.REACT_APP_S3_BUCKET + DIR_DEFAULT_RESOURCE + "defaultgirl.png";
export const DEFAULT_LIBRARY_LOGO = process.env.REACT_APP_S3_BUCKET + DIR_LIBRARY_IMAGE + "default_library_image.jpg";
export const DEFAULT_OLERN_LOGO = process.env.REACT_APP_S3_BUCKET + DIR_DEFAULT_RESOURCE + "olern-logo.png";
export const DEFAULT_OLERN_TRANSPARENT_LOGO = process.env.REACT_APP_S3_BUCKET + DIR_DEFAULT_RESOURCE + "olern-logo-transparent.png";
export const DEFAULT_WARNING_LOGO = process.env.REACT_APP_S3_BUCKET + DIR_DEFAULT_RESOURCE + "warning-logo.png";
export const DEFAULT_APPROVE_LOGO = process.env.REACT_APP_S3_BUCKET + DIR_DEFAULT_RESOURCE + "approve-logo.png";
export const DEFAULT_USER_IMAGE = process.env.REACT_APP_S3_BUCKET + DIR_USER_PROFILE + "default_user_image.png";
export const DEFAULT_RESTRICT_IMAGE = process.env.REACT_APP_S3_BUCKET + "/restrictImage.png";
export const DEFAULT_OLERN_OPTION_ICON = process.env.REACT_APP_S3_BUCKET + "/olern-icon.png";
export const DEFAULT_OLERN_WIDE_IMAGE = process.env.REACT_APP_S3_BUCKET + "/olern-wide-image.png";
export const TELLIX_LOGO = process.env.REACT_APP_S3_BUCKET + DIR_DEFAULT_RESOURCE + "tellix-logo.png";
export const TELLIX_LOGO_ICON = process.env.REACT_APP_S3_BUCKET + DIR_DEFAULT_RESOURCE + "tellix-logo-icon.png";
export const TELLIX_LOGO_BG = process.env.REACT_APP_S3_BUCKET + DIR_DEFAULT_RESOURCE + "tellix-logo-with-bg.png";

export const QUICK_ACCESS_BG = process.env.REACT_APP_S3_BUCKET + DIR_DEFAULT_RESOURCE + "QuickAccessBackground.jpg";
export const QUICK_ACCESS_LOGO = process.env.REACT_APP_S3_BUCKET + DIR_DEFAULT_RESOURCE + "quick-access-logo.png";
export const INTLIGUIDE_LOGO = process.env.REACT_APP_S3_BUCKET + DIR_DEFAULT_RESOURCE + "quick-access-logo-2.png";

export const QUOTES_TEMPLATE_URL = process.env.REACT_APP_S3_BUCKET + DIR_QUOTES_TEMPLATES;
export const COMPANY_LOGO_URL = process.env.REACT_APP_S3_BUCKET + DIR_COMPANY_IMAGE;

export const IMAGE_ELEMENT = 1;
export const AUDIO_ELEMENT = 2;
export const DOCUMENT_ELEMENT = 3;
export const VIDEO_ELEMENT = 4;
export const TEXT_ELEMENT = 5;
export const REFLECTION_ELEMENT = 6;
export const SIGNATURE_ELEMENT = 7;
export const SURVEY_ELEMENT = 8;
export const LINK_ELEMENT = 9;
export const DOWNLOAD_ELEMENT = 10;
export const QUIZ_ELEMENT = 11;
export const ACTIONS_STEP_ELEMENT = 12;
export const ACTION_SUMMARY_ELEMENT = 13;
export const WORD_CLOUD_ELEMENT = 14;
export const QUOTES_ELEMENT = 15;
export const RATING_ELEMENT = 16;
export const CUSTOM_PLAN_ELEMENT = 17;
export const INTELLIGUIDE_ELEMENT = 18;

//AI Generated Content
export const AI_QUIZ_ELEMENT = 101;

export const DEADLINE_TOMORROW = 1;
export const DEADLINE_TWO_WEEKS = 2;
export const DEADLINE_FOUR_WEEKS = 3;
export const DEADLINE_CUSTOM = 4;
export const DEADLINE_ONE_WEEK = 5;
export const DEADLINE_ONE_MONTH = 6;

export const WP_PRODUCT_URL = process.env.REACT_APP_WP_URL + process.env.REACT_APP_WP_PRODUCT_PATH;

export const SURVEY_SINGLE = 1;
export const SURVEY_MULTI = 2;
export const SURVEY_SORT = 3;

export const QUIZ_BOOL = 1;
export const QUIZ_MULTI = 2;
export const QUIZ_MULTI_ANS = 3;
export const QUIZ_SORTING = 4;

export const MASTER_PROMPT_HEADER = `\n and return it in this JSON format : `;
export const MASTER_PROMPT_FOOTER = `please do not provide introduction,
please do not describe your answer,
do not reply in any other format just in JSON,
do not include other text outside the array,
the whole response must be in a single array,
response must start with '[' and ends with ']'`;

export const AI_QUIZ_PROMPT_RETURN = MASTER_PROMPT_HEADER + 
`"[
	{
		"key": "",// a unique 21 character string
		"question" : "",
		"quiz_context" : {
			"question_type":"", // can be bool=(true or false)/choice=(single correct)/multi=(multiple correct)
			"points":2, // can be bool=2/choice=3/multi=5
			"explain": {
				"status": true, // always true
				"value":"" // explain why the choice is correct
			},
			"show_correct":true // always true
		},
		"quiz_choices" : [
			{"id":0,"description":"Choice A","context":"correct"},
			{"id":0,"description":"Choice B","context":"incorrect"}
			{"id":0,"description":"Choice C","context":"incorrect"}//id is always 0
		]//"multi" question type must have multiple correct in context, "bool" will always have only True or False as choices
	},
	// add the other object here
]"` +
MASTER_PROMPT_FOOTER; 

export const AI_REFLECTION_PROMPT_RETURN = MASTER_PROMPT_HEADER + 
`"[
	{
		"Id" : 0, 
		"answer" : "(Your Answer)"
	},
	// add the other object here
]"` +
MASTER_PROMPT_FOOTER; 

export const AI_TEXT_PROMPT_RETURN = `please do not describe your answer,
Return your response in html`


//Sales Process Constants
export const SP_CREATE_TAG = "Olern-Created-Account";
export const SP_COMPLETE_TAG = "Olern-Completed-Details";

export const HTTPS_LINK_PATTERN = /^https:\/\/(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^/?#]*)?\/?$/;
export const SP_FROM_QUICK_ACCESS = "new signup - qa";

export const PAYMENT_TYPES = [
	"All",
	"Apple Pay",
	"Google Pay",
	"Credits",
	"Credit Card",
	"Gcash",
	"Paymaya",
	"Paypal",
	"Bank Transfer",
	"Others",
];

export const PRODUCT_TYPES = [
	"All",
	"Course",
	"Credit",
]

export const LIBRARY_TYPE_TRAINING = 1;
export const LIBRARY_TYPE_MANUAL = 2;
export const LIBRARY_TYPE_POLICY = 3;
export const LIBRARY_TYPE_IN_PERSON_TRAINING = 4;
export const LIBRARY_TYPE_WEBINAR = 5;
export const LIBRARY_TYPE_INTELLIGUIDE = 6;
export const LIBRARY_TYPE_LEARNING_HABIT = 7;

export const SEARCH_TIMER_DELAY = 800;
export const SEARCH_RESULT_COUNT = 8;

export const DUE_DATE_SHORTCUTS = [
	{
		id: DEADLINE_TOMORROW,
		label: "Tommorow",
		day_add: 1,
	},
	{
		id: DEADLINE_ONE_WEEK,
		label: "In 1 Week",
		day_add: 7,
	},
	{
		id: DEADLINE_ONE_MONTH,
		label: "In 1 Month",
		day_add: 30,
	},
];

export const PERMISSIONS_VIEW_CHANNEL = 1
export const PERMISSIONS_EDIT_CHANNEL = 2	
export const PERMISSIONS_VIEW_CATEGORY = 3
export const PERMISSIONS_EDIT_CATEGORY = 4	
export const PERMISSIONS_VIEW_USERS = 5
export const PERMISSIONS_EDIT_USERS = 6
export const PERMISSIONS_VIEW_TEAMS = 7
export const PERMISSIONS_EDIT_TEAMS = 8
export const PERMISSIONS_VIEW_POSITIONS = 9	
export const PERMISSIONS_EDIT_POSITIONS = 10
export const PERMISSIONS_VIEW_CREDITS = 11
export const PERMISSIONS_EDIT_CREDITS = 12
export const PERMISSIONS_CREATE_LIBRARY = 13
export const PERMISSIONS_EDIT_LIB_OVERVIEW = 14
export const PERMISSIONS_EDIT_LIB_OUTLINE = 15
export const PERMISSIONS_EDIT_LIB_SETTINGS = 16
export const PERMISSIONS_PUBLISH_LIB = 17
export const PERMISSIONS_VIEW_ENROLL = 18
export const PERMISSIONS_ENROLL_STUDENTS = 19
export const PERMISSIONS_EDIT_STUDENT_ACCESS = 20
export const PERMISSIONS_ADD_LIB_MENTOR = 21
export const PERMISSIONS_ENROLL_COMPANY = 22
export const PERMISSIONS_ENROLL_TEAMS = 23
export const PERMISSIONS_ENROLL_POSITIONS = 24
export const PERMISSIONS_IMPORT_EXPORT_ENROLLEES = 25
export const PERMISSIONS_VIEW_ROLES = 26
export const PERMISSIONS_EDIT_ROLES = 27
export const PERMISSIONS_ADD_EMAIL = 28

export const SUPER_PERMISSIONS = [
	PERMISSIONS_VIEW_CHANNEL,
	PERMISSIONS_EDIT_CHANNEL,
	PERMISSIONS_VIEW_CATEGORY,
	PERMISSIONS_EDIT_CATEGORY,
	PERMISSIONS_CREATE_LIBRARY,
	PERMISSIONS_EDIT_LIB_OVERVIEW,
	PERMISSIONS_EDIT_LIB_OUTLINE,
	PERMISSIONS_EDIT_LIB_SETTINGS,
	PERMISSIONS_PUBLISH_LIB,
	PERMISSIONS_VIEW_ENROLL,
	PERMISSIONS_ENROLL_STUDENTS,
	PERMISSIONS_EDIT_STUDENT_ACCESS,
	PERMISSIONS_ADD_LIB_MENTOR,
	PERMISSIONS_ENROLL_COMPANY,
	PERMISSIONS_ENROLL_TEAMS,
	PERMISSIONS_ENROLL_POSITIONS,
	PERMISSIONS_IMPORT_EXPORT_ENROLLEES,
	PERMISSIONS_VIEW_ROLES,
	PERMISSIONS_EDIT_ROLES
]

export const MENTOR_DEFAULT_IMAGE = QUOTES_TEMPLATE_URL + "default_mentor_image.png"

export const QUOTES_TEMPLATES = [
	{
		"profile_image" : {"type": "image", "url" : MENTOR_DEFAULT_IMAGE, "pos_x": 130, "pos_y": 300,"pos_z": "below", "height": 140, "width": 140},
		"profile_name" : {"type": "text", "text" : "","pos_x": 110, "pos_y": 400,"width": 300, "size": 24, "align": "left", "color": "black", "family": "Open Sans"},
		"course_name": {"type": "text", "text" : "", "pos_x": 125, "pos_y": 430, "width": 300, "size": 16, "align": "left", "color": "black", "family": "Open Sans"},
		"quote": { "type": "text", "text" : "", "pos_x": 90, "pos_y": 80,"width": 300, "family": "Open Sans","size": 48, "color": "--theme-primary", "align": "left"},
		"images" : { "type": "image", "base": QUOTES_TEMPLATE_URL + "template_1.png", "preview" : QUOTES_TEMPLATE_URL + "template_1_preview.png"},
		"name" : "template_1"
	},
	{
		"profile_image" : {"type": "image", "url" : MENTOR_DEFAULT_IMAGE, "pos_x": 220, "pos_y": 180, "pos_z": "above", "height": 200, "width": 200},
		"profile_name" : {"type": "text", "text" : "", "pos_x": 50, "pos_y": 270,"width": 300, "size": 20, "align": "left", "color": "black", "family": "Open Sans"},
		"course_name": {"type": "text", "text" : "", "pos_x": 50, "pos_y": 295, "width": 200, "size": 14, "align": "left", "color": "black", "family": "Open Sans"},
		"quote": { "type": "text", "text" : "", "pos_x": 50, "pos_y": 100,"width": 140, "family": "Open Sans", "size": 16, "color": "--theme-black", "align": "left"},
		"images" : { "type": "image", "base": QUOTES_TEMPLATE_URL + "template_2.png", "preview" : QUOTES_TEMPLATE_URL + "template_2_preview.png"},
		"name" : "template_2"
	},
	{
		"profile_image" : {"type": "image", "url" : MENTOR_DEFAULT_IMAGE, "pos_x": 250, "pos_y": 350, "pos_z": "below", "height": 160, "width": 160},
		"profile_name" : {"type": "text", "text" : "", "pos_x": 50, "pos_y": 370,"width": 300, "size": 26, "align": "left", "color": "white", "family": "Open Sans"},
		"course_name": { "type": "text", "text" : "", "pos_x": 50, "pos_y": 400, "width": 300, "size": 14, "align": "left", "color": "white", "family": "Open Sans"},
		"quote": { "type": "text", "text" : "", "pos_x": 50, "pos_y": 100,"width": 335, "family": "Open Sans","size": 26, "color": "--theme-octonary", "align": "left"},
		"images" : { "type": "image", "base": QUOTES_TEMPLATE_URL + "template_3.png", "preview" : QUOTES_TEMPLATE_URL + "template_3_preview.png"},
		"name" : "template_3"
	},
]

export const DEFAULT_EMAIL_FOOTER = `<p>DID YOU KNOW... that our Olern App can personalize examples for you and your team?</p>
<br>
<p>Unlike other online learning platforms, we use Artificial Intelligence (AI) to make the learning experience tailor - fitted to your role and industry so it’s straight to the point and much easier to digest. It truly is a learning adventure...</p>
<br>
<p>Just imagine how much more personalized learning you and your team can get with the Olern App.</p>
<br>
<p>So download it now here: <a href="https://www.olern.com/download">https://www.olern.com/download</a></p>`

// dashboard constants - start
export const DATE_FRAME_THIS_MONTH = 1;
export const DATE_FRAME_LAST_MONTH = 2;
export const DATE_FRAME_THIS_YEAR = 3;
export const DATE_FRAME_ALL_TIME = 4;
export const COURSE_SELECTION_ALL_COURSES = 0;

export const COMPLETED_REPORT = 1;
export const ENROLLED_REPORT = 2;
export const IN_PROGRESS_REPORT = 3;
export const OVERDUE_REPORT = 4;
export const NOT_STARTED_REPORT = 5;
export const POINTS_REPORT = 6;

export const DEFAULT_LIBRARY_OPTION = {
	value: COURSE_SELECTION_ALL_COURSES,
	label: 'All Courses',
};

export const DEFAULT_DATE_FRAME_OPTIONS = [
	{
		value: DATE_FRAME_THIS_MONTH,
		label: 'This month'
	},
	{
		value: DATE_FRAME_LAST_MONTH,
		label: 'Last Month'
	},
	{
		value: DATE_FRAME_THIS_YEAR,
		label: 'This Year'
	},
	{
		value: DATE_FRAME_ALL_TIME,
		label: 'All-Time'
	},
];
// dashboard constants - start

export const LIBRARY_USERS_TAB_TABLE_SIZE = 10;

export const DEFAULT_PRIMARY_COLOR = '#F2663D';

export const ERROR_MESSAGES = [
	{
		error_code : "join_room_error",
		message : "User is already in room",
		sub_message : "Please check if other browser is already connected or contact the admin"
	},
	{
		error_code : "join_end_error",
		message : "Event has already ended",
		sub_message : "This event has concluded. Stay tuned for upcoming events!"
	},
]

export const EVENT_STATUS_PENDING = 1
export const EVENT_STATUS_START = 2
export const EVENT_STATUS_END = 3

export const THEME_PRIMARY_PROPERTY = '--theme-primary';
export const THEME_SIDEBAR_SECONDARY_PROPERTY = '--theme-sidebar-secondary-color';
export const THEME_TERTIARY_PROPERTY = '--theme-tertiary';

export const SIDEBAR_MAIN_DASHBOARD_PAGE = 0;
export const SIDEBAR_MAIN_ONLINE_COURSES_PAGE = 1;
export const SIDEBAR_MAIN_IN_PERSON_PAGE = 2;
export const SIDEBAR_MAIN_WEBINARS_PAGE = 3;
export const SIDEBAR_MAIN_INTELLIGUIDE_PAGE = 4;
export const SIDEBAR_MAIN_LEARNING_HABITS_PAGE = 5;
export const SIDEBAR_MAIN_COMPANIES_PAGE = 6;
export const SIDEBAR_MAIN_SETTINGS_PAGE = 7;

export const SIDEBAR_LIBRARY_OVERVIEW_PAGE = 8;
export const SIDEBAR_LIBRARY_HOME_PAGE = 9;
export const SIDEBAR_LIBRARY_TRAINING_OUTLINE_PAGE = 10;
export const SIDEBAR_LIBRARY_LESSON_DETAILS_PAGE = 11;
export const SIDEBAR_LIBRARY_USERS_PAGE = 12;
export const SIDEBAR_LIBRARY_SETTINGS_PAGE = 13;
export const SIDEBAR_LIBRARY_PRODUCT_PAGE = 14;
export const SIDEBAR_LIBRARY_WEBINAR_SETTINGS_PAGE = 15;

export const SIDEBAR_LEARNING_HABITS_HOME_PAGE = 16; // list of learning habit libraries 
export const SIDEBAR_LEARNING_HABITS_LESSONS_PAGE = 17; // libraries.js
export const SIDEBAR_LEARNING_HABITS_SAVE_TOPICS_PAGE = 18;
export const SIDEBAR_LEARNING_HABITS_SAVE_LESSONS_PAGE = 19;
export const SIDEBAR_LEARNING_HABITS_SAVE_SETTINGS_PAGE = 20;

export const SIDEBAR_LIBRARY_QA_BOARD_PAGE = 21;
export const SIDEBAR_LIBRARY_QA_DETAILS_PAGE = 22;
export const SIDEBAR_LIBRARY_INTELLIGUIDE_PAGE = 23;
export const SIDEBAR_LIBRARY_ASSISTANT_SETTINGS = 24;
export const SIDEBAR_ACTION_STEPS_PAGE = 25;

export const ZOOM_ROLE_PARTICIPANT = 0
export const ZOOM_ROLE_HOST = 1

export const EMAIL_PENDING = 0;
export const EMAIL_APPROVED = 1;
export const EMAIL_REJECTED = 2;
export const EMAIL_SENT = 3;

export const COMMENT_LIKE = 1;
export const COMMENT_DISLIKE = 0;
export const COMMENT_STATUS_APPROVED = "approved";
export const COMMENT_STATUS_PENDING = "pedning";
export const COMMENT_STATUS_REJECTED = "rejected";

export const ZOOM_WEBSOCKET_URL = `${process.env.REACT_APP_WEBSOCKET}/zoom`;